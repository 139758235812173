import { useEffect } from 'react'
import { postMessage } from './Message'
import { PaymentTokenResponse, SingleUseToken } from './PaymentInterface'

const createPaymentTokenResponse = (tokenResponse: SingleUseToken): PaymentTokenResponse => {
    return {
        type: 'PaymentTokenResponse',
        token: tokenResponse.singleUseTokenId,
        method: 'CARD',
        provider: tokenResponse.creditCard.cardScheme,
        optionTitle: getOptionTile(tokenResponse.creditCard.cardNumber, tokenResponse.creditCard.cardType!)
    }
}

const toTitleCase = (text: string): string => {
    return text.substring(0, 1).toUpperCase() + text.substring(1).toLowerCase()
}

const getOptionTile = (cardNumber: string, cardType: string): string => {
    const cardTypeToReturn = !cardType ? 'CREDIT' : cardType
    return `***${cardNumber.split('...').pop()} (${toTitleCase(cardTypeToReturn)} Card)`
}

const safeParseStringToJson = (str: string): any | undefined => {
    let data
    try {
        data = JSON.parse(str)
    } catch (error) {}
    return data
}

export const useMessageHandler = (): void => {
    const handleMessage = (message: MessageEvent) => {
        if (!message || !message.data || typeof message.data !== 'string') return

        const eventData = safeParseStringToJson(message.data)
        if (!eventData || !eventData.type || !eventData.result) return

        if (eventData.type === 'CreateQuickStreamSingleUseTokenResponse') {
            if (
                eventData.result.status !== 'OK' ||
                !eventData.result.singleUseToken ||
                !eventData.result.singleUseToken.singleUseTokenId
            ) {
                return
            }
            const responseData = JSON.stringify(createPaymentTokenResponse(eventData.result.singleUseToken))
            postMessage(responseData)
        } else if (eventData.type === 'InitialiseQuickStreamCreditCardFrameResponse') {
            if (eventData.result.status === 'OK') {
                const responseData = JSON.stringify({ type: 'PaymentInitialisationResponse' })
                postMessage(responseData)
            } else {
                postMessage(
                    JSON.stringify({
                        type: 'PaymentWebError',
                        message: eventData.result.message || 'Unknown error'
                    })
                )
            }
        }
    }

    useEffect(() => {
        window.addEventListener('message', handleMessage, true)
        return window.removeEventListener('message', () => null)
    }, [])
}
