import React from 'react'
import { CardForm } from './card/CardForm'
import { useMessageHandler } from './MessageHooks'
import { PayPal } from './PayPal'

const App = (): React.JSX.Element => {
    useMessageHandler()

    const queryString = window.location.search
    const searchParams = new URLSearchParams(queryString)

    if (searchParams && searchParams.has('option')) {
        const option = searchParams.get('option')
        const amount = searchParams.get('amount')
        if (option === 'paypal' && amount) {
            return (
                <PayPal
                    testID='paypal-iframe-wrapper'
                    amount={amount}
                />
            )
        } else if (option === 'card') {
            return <CardForm testID='card-iframe-wrapper' />
        }
    }
    return <></>
}
export default App
