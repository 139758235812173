/* eslint-disable */
export const postMessage = (tokenResponse: string): void => {
    // @ts-ignore
    if (window['SnswMobilePopupWebView']) {
        // @ts-ignore
        window['SnswMobilePopupWebView'].postMessage(tokenResponse)
        // @ts-ignore
    } else if (window['ReactNativeWebView']) {
                // @ts-ignore
        window['ReactNativeWebView'].postMessage(tokenResponse)
    }
}
