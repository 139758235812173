import React from 'react'

export interface PayPalProps {
    testID: string
    amount: string
}
export const PayPal = (props: PayPalProps): React.JSX.Element => {
    const iframeRef = React.useRef<HTMLIFrameElement>(null)
    const targetOrigin = 'https://' + process.env.REACT_APP_CPP_WEB_HOST + '/frame/paypal'

    return (
        <>
            <iframe
                className='iframe'
                data-testid={props.testID}
                src={targetOrigin}
                width='100%'
                height={335}
                ref={iframeRef}
                scrolling='no'
                onLoad={() => {
                    setTimeout(() => {
                        if (!iframeRef.current) return
                        iframeRef.current.contentWindow!.postMessage(
                            {
                                type: 'InitialisePayPalButtonRequest',
                                options: {
                                    style: {
                                        color: 'blue',
                                        shape: 'rect',
                                        label: 'paypal',
                                        tagline: false,
                                        size: 'responsive',
                                        layout: 'horizontal'
                                    }
                                },
                                amount: parseFloat(props.amount)
                            },
                            targetOrigin
                        )
                    }, 0)
                }}
            ></iframe>
        </>
    )
}
