import React from 'react'
import './CardForm.css'
import { getOptions } from './QuickstreamFrameOptions'

export interface CardFormProps {
    testID: string
}

export const CardForm = (props: CardFormProps): React.JSX.Element => {
    const iframeRef = React.useRef<HTMLIFrameElement>(null)
    const targetOrigin = 'https://' + process.env.REACT_APP_CPP_WEB_HOST + '/frame/credit-card'

    return (
        <>
            <iframe
                className='iframe'
                data-testid={props.testID}
                src={targetOrigin}
                width='100%'
                height={335}
                ref={iframeRef}
                scrolling='no'
                onLoad={() => {
                    setTimeout(() => {
                        if (!iframeRef.current) return
                        iframeRef.current.contentWindow!.postMessage(
                            {
                                type: 'InitialiseQuickStreamCreditCardFrameRequest',
                                options: getOptions()
                            },
                            targetOrigin
                        )
                    }, 1000)
                }}
            ></iframe>
            <div className='button-container'>
                <button
                    className='button-primary'
                    onClick={() => {
                        iframeRef!.current!.contentWindow!.postMessage(
                            { type: 'CreateQuickStreamSingleUseTokenRequest' },
                            targetOrigin
                        )
                    }}
                >
                    Continue
                </button>
            </div>
        </>
    )
}
