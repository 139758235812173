const inputStyle = {
    height: '34px',
    padding: '0px 12px',
    'font-size': '14px',
    border: '1px solid #ccc',
    'border-radius': '2px'
}

const label = {
    'font-size': '16px',
    color: '#242934'
}

export const getOptions = (): object => {
    return {
        config: {
            supplierBusinessCode: 'DOLAPI'
        },
        showAcceptedCards: true,
        cardNumber: {
            label: 'Card number',
            inputType: 'number',
            style: inputStyle
        },
        cardholderName: {
            hidden: true
        },
        expiryDateMonth: {
            style: inputStyle
        },
        expiryDateYear: {
            style: inputStyle
        },
        cvn: {
            label: 'Security code',
            showHelp: true,
            style: {
                ...inputStyle,
                width: '80px'
            },
            inputType: 'number'
        },
        iframe: {
            width: '100%',
            height: '315',
            style: {
                width: '100%',
                'background-color': 'white'
            }
        },
        labels: {
            style: label
        }
    }
}
